<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="pt-2 px-2">
          รายการคืนยอดเสีย
        </div>
      </b-card-title>
      <b-card-body>
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                ค้นหา ยูส, เบอร์โทร
              </div>
            </div>

            <b-form-input
              v-model="term"
              class="form-control"
              placeholder="ค้นหา"
            />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="getData()"
          >
            ค้นหา
          </button>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        small
        responsive
        show-empty
        class="mt-2 position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(bank)="{ item }">
          <img
            :src="`/bankIcon/${item.user_bankimg}`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          >
        </template>
        <template #cell(cashback_amount)="{ item }">
          {{ parseFloat(item.cashback_amount).toFixed(2) }}
        </template>
        <template #cell(created_at)="{ item }">
          {{ item.created_at | dateFormat() }}
        </template>

      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    perPage: 100,
    pageOptions: [100, 150, 200],
    totalRows: 1,
    page: 1,
    fields: [
      { key: 'tel', label: 'เบอร์โทร' },
      { key: 'winlost', label: 'Win/Lost', sortable: true },
      { key: 'cashback_percent', label: '% จากยอดเสีย', sortable: true },
      { key: 'cashback_amount', label: 'รายได้จากยอดเสีย', sortable: true },
      { key: 'commission_percent', label: '% จากคอมมิชชั่น', sortable: true },
      { key: 'commission_amount', label: 'รายได้จากคอมมิชชั่น', sortable: true },
      { key: 'commission_friend_percent', label: '% รายได้ของผู้แนะนำ', sortable: true },
      { key: 'commission_friend_amount', label: 'รายได้ของผู้แนะนำ', sortable: true },
      { key: 'type', label: 'ประเภท' },
      { key: 'date', label: 'ยอดเสียวันที่' },
      { key: 'created_at', label: 'วันเวลาดึงข้อมูล' },
    ],
    items: [],
    isLoading: false,
    term: '',
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(1, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
  }),
  mounted() {
    this.getData()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async getData() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/bonus/cashback/getCashback', {
          params: {
            page: this.page, perPage: this.perPage, dateStart: this.dateStart, dateEnd: this.dateEnd, term: this.term,
          },
        })

        this.totalRows = data.total
        this.items = data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
